import Images from "@services/utils/Images";
import { useState } from "react";

const StaticNavAppDownload = ({ fixed, browserInfo }: { fixed: boolean; browserInfo?: any }) => {
  const isIosMobile = !!browserInfo?.isIosMobile;
  const androidAppLink =
    "https://play.google.com/store/apps/details?id=com.irctc.fot&utm_source=ecatering&utm_campaign=mobile-web-home-sticky-banner";
  const androidAppLinknewBanner =
    "https://play.google.com/store/apps/details?id=com.irctc.fot&utm_source=ecatering&utm_campaign=web-new-order-banner";
  const iosAppLink = isIosMobile ? "https://apps.apple.com/in/app/irctc-catering-food-on-track/id1077183717" : null;
  const [hideBanner, setHideBanner] = useState<boolean>(false);
  return hideBanner ? null : (
    <div>
      <div className={fixed ? "flex w-full items-center px-5 py-2" : "flex w-full items-center py-3"}>
        <div className="mr-2.5 text-sm text-black ">
          <img src={Images.eCateringLogo} alt="logo" />
        </div>
        <div>
          <p className="mr-1.5 font-sans font-medium">Get our app for the best experience</p>
        </div>
        <div className={fixed ? "ml-0.5 mr-3" : ""}>
          {fixed ? (
            <a href={iosAppLink ?? androidAppLink} target="_blank">
              {" "}
              <button className={fixed ? "btn-primary" : "btn-primary px-3 text-xs leading-9"}>Install</button>
            </a>
          ) : (
            <a href={iosAppLink ?? androidAppLinknewBanner} target="_blank">
              {" "}
              <button className={fixed ? "btn-primary" : "btn-primary px-3 text-xs leading-9"}>Install</button>
            </a>
          )}
        </div>
        {fixed && (
          <strong onClick={() => setHideBanner(true)} className="alert-del cursor-pointer text-2xl">
            &times;
          </strong>
        )}
      </div>
    </div>
  );
};

export default StaticNavAppDownload;
