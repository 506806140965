import React from "react";
import Link from "next/link";
import { useConfig } from "@services/globalConfig";
import Card from "@components/Card";

interface HomepageBanner {
  isMobile: boolean;
}

const HomepageBanner: React.FC<HomepageBanner> = ({ isMobile }) => {
  const config = useConfig();

  return (
    <>
      {!config?.banner_card_redirection ? (
        <Card className="flex flex-row my-4 mx-4 rounded-md shadow-card">
          <img
            src={config?.banner_card_image}
            srcSet={config?.banner_card_image_srcset}
            alt={config?.banner_card_image_alt || ""}
            className="max-w-banner rounded-md"
          />
        </Card>
      ) : (
        <Card className="flex flex-row my-4 mx-4 rounded-md shadow-card ">
          <Link href={config?.banner_card_redirection || ""} legacyBehavior>
            <img
              srcSet={config?.banner_card_image_srcset}
              src={config?.banner_card_image}
              alt={config?.banner_card_image_alt || ""}
              className="max-w-banner rounded-md"
            />
          </Link>
        </Card>
      )}
    </>
  );
};

export default HomepageBanner;
