import Link from "next/link";
import React, { useEffect, useState } from "react";

import { BottomNavbar } from "@components/HeadersFooters";
import Image from "@components/Image";
import { AboutECateringText } from "@components/PageComponents/home/AboutECateringText";
import GroupBooking from "@components/PageComponents/home/GroupBooking";
import HomepageBanner from "@components/PageComponents/home/HomepageBanner";
import HowItWorks from "@components/PageComponents/home/HowItWorks";
// import VigilanceWeekBanner from "@components/PageComponents/home/VigilanceWeekBanner";
import { ReCAPTCHA } from "@components/Recaptcha";
import JourneyHistory from "@components/StationsComponents/StationCardItem";
import { HomeProps } from "@interfaces/index";
import { useConfig } from "@services/globalConfig";
import { Config } from "@services/utils/Config";
import Images from "@services/utils/Images";
import Head from "next/head";
import StaticNavAppDownload from "./StaticNavAppDownload.tsx";

type MobileHomeProps = Omit<HomeProps, "stations" | "blogPosts">;
export const MobileHome: React.FC<MobileHomeProps> = ({
  renderPnrField,
  historyData,
  handleJourneyItemClick,
  isIosMobile,
  renderSearchByField,
  seoContent,
  browserInfo,
}) => {
  const [mounted, setMounted] = useState(false);
  const UseConfig = useConfig();
  const isMobile = !!browserInfo?.isMobile;
  const isWebview = !!browserInfo?.isWebview;

  useEffect(() => {
    setMounted(true);
  }, []);

  const Logos = () => (
    <div className="p-4" style={{ width: "100%", position: "absolute", top: 0, left: 0 }}>
      <div
        className="mt-24 rounded-xl bg-white"
        style={{
          marginLeft: "calc(50% - 45px)",
          width: 90,
          height: 90,
        }}
      >
        <div
          style={{
            float: "right",
            backgroundImage: `url(${Images.eCateringLogo})`,
            backgroundSize: "auto",
            width: 90,
            height: 90,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
    </div>
  );
  //TODO: in future move this to firebase config
  const pantry_home_banner = false;

  return (
    <div className="container pb-14">
      <Head>
        <link rel="preload" as="image" href={Images.homepageBg.mobile2} />
        <link rel="preload" as="image" href={Images.eCateringLogo} />
        <link rel="preload" as="image" href={Images.irctc} />
        <link rel="preload" as="image" href={Images.amritEnglish()} />
      </Head>
      {/*UPPER HALF*/}
      <div className="relative mb-15 flex h-100 flex-col items-center bg-black bg-home bg-cover bg-no-repeat pt-32 shadow-inner">
        <Logos />
        <div className="home-page-title mx-4 mt-24 text-center text-2xl font-bold tracking-wider text-white">
          Get restaurant food delivered to your train seat
        </div>
        <div className="absolute bottom-12 flex w-full px-4">{renderSearchByField()}</div>
        <div className="absolute -bottom-7 flex w-full px-4">{renderPnrField()}</div>
      </div>

      {/*LOWER HALF*/}

      {!!pantry_home_banner ? (
        <div className="relative mx-4 mb-6 rounded-xl border border-gray-30 p-4">
          <span className="absolute left-[12px] top-[-12px] rounded bg-gray-30 px-2 py-0.5 text-xs font-medium text-info-primary">New</span>
          <p className="text-small items-center">Now also book train pantry meals on selected trains</p>
        </div>
      ) : null}
      {mounted ? (
        <>
          {!!historyData.length && (
            <JourneyHistory
              historyData={historyData}
              layout={"card"}
              showEmptyMessage={false}
              handleJourneyItemClick={handleJourneyItemClick}
              showTitle
              isMobile
            />
          )}
          {!!UseConfig?.banner_card_image ? <HomepageBanner isMobile /> : null}
        </>
      ) : null}

      <div className="mb-6 px-4 pb-1">
        <div
          className={
            "flex max-h-40 cursor-pointer flex-col items-center justify-center gap-3 overflow-y-hidden rounded bg-white object-none px-3 py-4 shadow-card"
          }
        >
          <div className="flex gap-3">
            <div className="w-2/3">
              <img className={"h-10"} src={Images.irctcTitleImg} alt={"IRCTC tagline image"} />
            </div>

            <div className="w-1/3">
              <img className="h-10" src={Images.irctcTrainArtWork} alt={"IRCTC tagline image"} />
            </div>
          </div>
          <hr className="w-4/5 bg-gray-600" />
          <div>
            <p className="text-center text-sm font-medium tracking-[0.1px]">
              Official website of Indian Railways and <br /> IRCTC to order food on trains
            </p>
          </div>
        </div>
      </div>

      <GroupBooking isMobile />

      {historyData.length === 0 && (
        <div className="mb-10 px-4">
          <HowItWorks isMobile />
        </div>
      )}
      {Config.adsenseClient ? (
        <div className={"mb-6 items-center px-4"}>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client={Config.adsenseClient}
            data-ad-slot={Config.adsenseSlots.home}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </div>
      ) : null}
      <div className={"page-seo-content mb-6 items-center px-4"}>
        {/* <div className="subtitle-1 mb-3">About eCatering</div> */}
        <AboutECateringText className="flex flex-col gap-4 text-sm" seoContent={seoContent} />
      </div>
      <div className="mb-6 items-center px-4 text-sm text-gray-50">
        <Link href="/blog" className="cursor-pointer underline">
          Checkout our blog
        </Link>
      </div>

      {/* <VigilanceWeekBanner isMobile /> */}

      <div className={"mb-6 items-center px-4 text-sm text-gray-50"}>
        By placing your order, you accept our{" "}
        <Link href="/tnc" className="cursor-pointer underline">
          Terms and Conditions
        </Link>
      </div>
      <div className={"mb-6 items-center px-4 text-sm text-gray-50"}>
        Follow{" "}
        <Link href="/sitemap" className="cursor-pointer underline">
          Sitemap
        </Link>{" "}
        to get links
      </div>
      <div className={"mt-6 items-center px-4 text-sm text-gray-50"}>
        <ReCAPTCHA />
      </div>
      <div className="mx-4 block pt-4">
        <strong className="block">Connect with us</strong>
        <a href="https://www.facebook.com/cateringIRCTC/" className="inline-block align-middle" target="_blank" rel="noopener noreferrer">
          <Image src={Images.icons.fb} alt="Facebook eCatering" removeBorder={true} />
        </a>
        <a href="https://twitter.com/ecateringirctc" className="inline-block align-middle" target="_blank" rel="noopener noreferrer">
          <Image src={Images.icons.tw} alt="Twitter eCatering" removeBorder={true} />
        </a>
        <a href="https://www.instagram.com/ecateringirctc/" className="inline-block align-middle" target="_blank" rel="noopener noreferrer">
          <Image src={Images.icons.in} alt="Instagram eCatering" removeBorder={true} />
        </a>
        <a href="https://www.youtube.com/c/IRCTCOFFICIAL/" className="ml-1 inline-block align-middle" target="_blank" rel="noopener noreferrer">
          <Image src={Images.icons.yt} alt="Youtube eCatering" removeBorder={true} />
        </a>
      </div>
      <div className="fixed bottom-12 z-auto mb-1 bg-white shadow-top ">
        {isIosMobile || isWebview ? null : <StaticNavAppDownload fixed={true} browserInfo={browserInfo} />}
      </div>

      <BottomNavbar isIosMobile={isIosMobile} />
    </div>
  );
};
