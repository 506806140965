import { ApiCookieFlags, ApiResponseStorageKeys } from "@enums/ApiUtils";
import useServerClientData from "./useServerClientData";

const useStationSearchQuery = ({ serverData }: { serverData: any }) => {
  const isServerDataAvailable = !!serverData?.result?.stations;
  const [homeData] = useServerClientData({
    isServerDataAvailable,
    serverData,
    url: "/api/v2/home",
    getWithCaptcha: true,
    cookieFlag: ApiCookieFlags.homeData,
    storageKey: ApiResponseStorageKeys.homeData,
    checkDataAv: (data) => !!data?.stations,
  });

  return [homeData];
};

export default useStationSearchQuery;
