import { ArrowIcon, CalendarIcon, IconFilledTrain, IconNoResults, Loader, RefreshIcon } from "@assets/icons";
import Input from "@components/Common/input";
import { ListShowMoreComponent } from "@components/ListShowMoreComponent";
import { StorageRef } from "@enums/common";
import { EventName, EventProperty } from "@enums/events";
import useCurrentStationInfo from "@hooks/useCurrentStationInfo";
import useDebounce from "@hooks/useDebounce";
import useStationSearchQuery from "@hooks/useStationSearchQuery";
import { ApiResponse, SearchQueryStationInfo, SearchQueryTrainInfo, SearchStationsTrainsInfo, SelectedQueryInfo } from "@interfaces/index";
import Analytics from "@services/analytics";
import { useConfig } from "@services/globalConfig";
import {
  clearQuerySelectedTrainInfoInStorage,
  getQueryTrainInfoFromStorage,
  getQueryTrainSearchString,
  reverseDate,
  setQueryTrainInfoInStorage,
} from "@services/utils/Common";
import { dateTimeFormatter } from "@services/utils/DateTimeFormatter";
import { CaptchaGet } from "@services/utils/Request";
import { Storage } from "@services/utils/Storage";
import { toast } from "@utils/Notification";
import { useRouter } from "next/router";
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import useSWR from "swr";
import SearchInputField from "./SearchInputField";

type Props = {
  serverHomeResponse: any;
  closeSearchQueryModal: () => void;
  showSearchQueryModal: boolean;
  isMounted: boolean;
  toggleTrainMode: () => void;
  findByTrainMode: boolean;
  isMobile: boolean;
};

const getInitialSearchQueryData = () => ({ stations: [], trains: [] });

type BoardingStationInfo = { name: string; code: string; outletCount: number };

export const TrainStationSearchQuery: React.FC<Props> = ({
  serverHomeResponse,
  closeSearchQueryModal,
  showSearchQueryModal,
  toggleTrainMode,
  isMounted,
  findByTrainMode,
  isMobile,
}) => {
  const [homeResponse] = useStationSearchQuery({ serverData: serverHomeResponse });
  const [searchQueryData, setSearchQueryData] = useState<SearchStationsTrainsInfo>(getInitialSearchQueryData());
  const [isSearchQueryInProgress, setSearchQueryInProgress] = useState(false);
  const currentSearchQuery = useRef("");
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState(getQueryTrainSearchString() || "");

  const selectedQueryInfoFromStorage = useMemo(() => getQueryTrainInfoFromStorage(), []);

  const [selectedTrainInfo, setSelectedTrainInfo] = useState<SelectedQueryInfo["trainInfo"] | undefined>(selectedQueryInfoFromStorage?.trainInfo);
  const [selectedBoardingStation, setSelectedBoardingStation] = useState(selectedQueryInfoFromStorage?.boardingStation || "");
  const [boardingDate, setBoardingDate] = useState<string>(selectedQueryInfoFromStorage?.boardingDate || "");
  const { setCurrentStationInfo } = useCurrentStationInfo();

  const {
    data: boardingStations,
    isValidating: showBoardingStationsSpinner,
    mutate: refreshBoardingStations,
  } = useSWR<BoardingStationInfo[]>(
    [selectedTrainInfo, boardingDate],
    (selectedTrainInfo, boardingDate) =>
      selectedTrainInfo && !!boardingDate
        ? CaptchaGet({
            url: `/api/v2/train/boarding/stations?trainNo=${selectedTrainInfo?.trainNo}&boardingDate=${reverseDate(boardingDate)}`,
          }).then((res: ApiResponse<BoardingStationInfo[]>) => {
            if (Array.isArray(res.result)) {
              // const __selectedBoardingStation = res.result.find((station) => station.code === selectedBoardingStation);
              // if (!__selectedBoardingStation) {
              //   setSelectedBoardingStation(res.result[0].code);
              // }
              return res.result;
            }
            return [];
          })
        : [],
    { revalidateOnFocus: false }
  );
  const config = useConfig();

  const makeSearchQueryCall = useDebounce(async (searchQuery: string) => {
    // Add cancellation of requests if search query is stale
    const response = (await CaptchaGet({ url: "/api/v2/train/search?query=" + searchQuery })) as ApiResponse<SearchStationsTrainsInfo>;
    setSearchQueryInProgress(false);
    if (searchQuery === currentSearchQuery.current && response?.result) {
      // Make sure the current search query and the query response is of the same query)
      setSearchQueryData(response.result);
    }
  }, 500);

  const onQueryChange = (query: string) => {
    if (!findByTrainMode) {
      setSearchQuery(query);
      Storage.set(StorageRef.QueryStringForTrainSearch, query);
    }
  };

  const searchInputFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMounted && !findByTrainMode) {
      currentSearchQuery.current = searchQuery;
      if (searchQuery.length > 2) {
        setSearchQueryInProgress(true);
        makeSearchQueryCall(searchQuery.trim());
      } else {
        setSearchQueryData(getInitialSearchQueryData());
      }
    }
  }, [searchQuery, findByTrainMode, isMounted]);

  useEffect(() => {
    if (!showSearchQueryModal) {
      // reset state on query modal close
      clearSelectedTrainInfo();
    } else if (!isMobile) {
      searchInputFieldRef.current?.focus();
    }
  }, [showSearchQueryModal, isMobile]);

  const clearSelectedTrainInfo = (hideTrainMode = false) => {
    setSelectedTrainInfo(undefined);
    setSearchQuery(hideTrainMode ? getQueryTrainSearchString() || "" : "");
    setBoardingDate("");
    setSelectedBoardingStation("");
    clearQuerySelectedTrainInfoInStorage();
    if (hideTrainMode) {
      toggleTrainMode();
    }
  };

  useEffect(() => {
    const selectedBoardingStationInfo = boardingStations?.find((station) => station.code === selectedBoardingStation);
    setQueryTrainInfoInStorage(selectedTrainInfo, boardingDate, selectedBoardingStationInfo || { code: selectedBoardingStation });
  }, [boardingDate, selectedBoardingStation, searchQuery, boardingStations]);

  const handleTrainItemClick = (train: SearchQueryTrainInfo) => {
    const searchString = train.trainNo + "  " + train.trainName;
    setSelectedTrainInfo({ ...train, searchString });
    toggleTrainMode();
  };

  const renderTrainListItem = (train: SearchQueryTrainInfo, isLastItem: boolean) => (
    <button
      key={train.trainNo}
      className={`body-3 h-10 w-full px-4 text-left leading-relaxed text-gray-60 ${!isLastItem ? "border-b" : ""}`}
      onClick={() => handleTrainItemClick(train)}
    >
      {train.trainNo}&nbsp;&nbsp;{train.trainName}
    </button>
  );

  const onStationSelection = (stationInfo: SearchQueryStationInfo) => {
    // TODO: change naming convention of stationInfo.name to stationInfo.code as it is the station code
    // PNR flow -> station selection screen on click on station
    Analytics.track(EventName.VIEW_ITEM_LIST, { [EventProperty.CONTENT_TYPE]: "Station", [EventProperty.ITEM_CATEGORY]: stationInfo.name });
    Storage.set(StorageRef.ShowTrainSearchQuery, true);
    setCurrentStationInfo({ code: stationInfo.code, name: stationInfo.name });
    router.push({ pathname: "/station/[stationCode]/outlets/", query: { stationCode: stationInfo.code } });
  };

  const renderStationListItem = (station: SearchQueryStationInfo, isLastItem: boolean) => (
    <button
      key={station.code}
      className={`flex h-10 w-full items-center justify-between px-4 text-left leading-relaxed ${!isLastItem ? "border-b" : ""}`}
      onClick={() => onStationSelection(station)}
    >
      <span className="subtitle-2">{station.name}</span>
      <span className="body-2 text-gray-60">{station.code}</span>
    </button>
  );

  const minDateForBoarding = useMemo(() => {
    const d = new Date();
    d.setDate(d.getDate() - 5);
    return dateTimeFormatter(d.toString(), "2-digit", 24).dateStringNumeric;
  }, []);

  const handleSetBoardingDate = (e: ChangeEvent<HTMLInputElement>) => {
    const boardingDate = e.target.value;
    setBoardingDate(boardingDate);
  };

  const handleGoBack = () => {
    if (findByTrainMode) {
      clearSelectedTrainInfo(true);
    } else {
      closeSearchQueryModal();
    }
  };

  const handleClickFindFood = () => {
    if (boardingDate && selectedTrainInfo && selectedBoardingStation) {
      router.push({
        pathname: "/train/[trainNo]/[stationCode]",
        query: {
          trainNo: selectedTrainInfo.trainNo,
          stationCode: selectedBoardingStation,
          boardingDate,
        },
      });
    } else {
      toast.notify("Please pick Boarding Date & Boarding Station");
    }
  };

  return (
    <>
      <div className="border-b p-4 pb-3">
        <div className="mb-1 flex">
          <button onClick={handleGoBack}>
            <ArrowIcon className="-ml-1 h-5 w-5 " />
          </button>
          <p className="body-1 ml-4">Find food via Train Details {!findByTrainMode ? "or Station" : ""}</p>
        </div>
        <SearchInputField
          placeholder="Start typing Nagpur, NGP, Rajdhani"
          className="text-sm"
          onClick={findByTrainMode ? () => clearSelectedTrainInfo(true) : undefined}
          onValueChange={!findByTrainMode ? onQueryChange : undefined}
          showSpinner={isSearchQueryInProgress}
          searchQuery={findByTrainMode ? selectedTrainInfo?.searchString : searchQuery}
          isDesktop={!isMobile}
          ref={searchInputFieldRef}
        />
      </div>
      <div className="top-109px absolute h-full overflow-y-auto">
        {findByTrainMode ? (
          <div className="p-4">
            <p className="body-1 mb-4">Pick your boarding date & then boarding station</p>
            <div className="relative mb-6">
              <CalendarIcon className="absolute left-3 top-2.5 z-10 text-gray-40" />
              <Input
                className={"form-input custom-date-picker h-11.5 pl-12 text-sm"}
                type={"date"}
                name={"date"}
                placeholder={"Boarding Date"}
                min={minDateForBoarding}
                value={boardingDate}
                onChange={handleSetBoardingDate}
                onKeyDown={(e) => e.preventDefault()}
              />
              {!boardingDate && <span className="body-1 pointer-events-none absolute left-12 top-3 bg-white text-gray-60">Boarding Date</span>}
            </div>
            <div className="relative mb-6">
              <IconFilledTrain className="active fill-current absolute left-3 top-2.5 text-gray-40" />
              <select
                placeholder="Boarding Station"
                className="form-input h-11.5 appearance-none pl-12 text-sm"
                value={selectedBoardingStation}
                onChange={(e) => setSelectedBoardingStation(e.target.value)}
              >
                <option value="">Boarding Station</option>
                {boardingStations?.map((station) => (
                  <option value={station.code} key={station.code}>
                    {station.name} - {station.code}
                  </option>
                ))}
              </select>
              {!boardingStations?.length && (
                <div className="absolute top-0 right-2 flex h-full w-full items-center justify-end">
                  {
                    showBoardingStationsSpinner ? (
                      <Loader color="text-brand-primary" />
                    ) : !!boardingDate && !boardingStations?.length ? (
                      <button
                        className="flex h-full w-full items-center justify-end"
                        onClick={!!boardingDate ? () => refreshBoardingStations() : undefined}
                      >
                        <RefreshIcon color="#999" />
                      </button>
                    ) : null
                    // <IconChevronRight className="fill-current text-gray-60 transform rotate-90" />
                  }
                </div>
              )}
            </div>
            <button className="btn-primary w-full" onClick={handleClickFindFood}>
              FIND FOOD
            </button>
          </div>
        ) : searchQuery.length > 2 ? (
          !searchQueryData.stations.length && !searchQueryData.trains.length && !isSearchQueryInProgress ? (
            <div className="mx-auto ml-24 flex h-full flex-1 flex-col items-center justify-start">
              <IconNoResults className="text-9xl" color="#BC5757" fontSize="200px" />
              <p className="subtitle-1 mb-1">No results for &apos;{searchQuery}&apos;.</p>
              <span className="body-1">Please try another keyword.</span>
            </div>
          ) : (
            <div className={`${isMobile ? "w-screen" : "w-full"}`}>
              {!!searchQueryData.trains.length && (
                <ListShowMoreComponent
                  title="Trains"
                  data={searchQueryData.trains}
                  renderListItem={renderTrainListItem}
                  noOfItemsToShowOnCollapsed={config?.search_train_item_count}
                />
              )}
              {!!searchQueryData.stations.length && (
                <ListShowMoreComponent
                  title="Stations"
                  data={searchQueryData.stations}
                  renderListItem={renderStationListItem}
                  noOfItemsToShowOnCollapsed={config?.search_station_item_count}
                />
              )}
            </div>
          )
        ) : (
          <div>
            <div className="pt-4">
              <p className="subtitle-2 mb-4 pl-4">Top Trains</p>
              {homeResponse?.trains
                .slice(0, config?.search_train_item_count)
                .map((train, i, arr) => renderTrainListItem(train, i === arr.length - 1))}
            </div>
            <div className="p-4">
              <p className="subtitle-2 mb-4">Top Stations</p>
              <div className="flex flex-wrap gap-4 text-sm">
                {homeResponse?.stations.slice(0, config?.search_station_item_count).map((station) => (
                  <button key={station.name} className="body-3 rounded border p-2" onClick={() => onStationSelection(station)}>
                    {station.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
