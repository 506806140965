import Image from "@components/Image";
import React from "react";

type BrandList = {
  isMobile: boolean;
};

const vendorImages = [
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686131/irctc/vendor/homeLogos/dominos.webp", name: "Dominos" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75,w_240/v1688637297/irctc/vendor/homeLogos/rebel-ovenstory.webp", name: "Ovenstory" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75,w_240/v1688637297/irctc/vendor/homeLogos/rebel-faasos.webp", name: "Faasos" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75,w_240/v1688637297/irctc/vendor/homeLogos/rebel-lunchbox.webp", name: "Lunchbox" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75,w_240/v1688637297/irctc/vendor/homeLogos/rebel-behrouz.webp", name: "Behrouz Biryani" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75,h_120/v1682676435/irctc/vendor/homeLogos/hotplate.webp", name: "Railofy Hotplate" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686131/irctc/vendor/homeLogos/comesum.webp", name: "Comesum" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686131/irctc/vendor/homeLogos/zoop.webp", name: "Zoop" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686132/irctc/vendor/homeLogos/relfood.webp", name: "Relfood" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686132/irctc/vendor/homeLogos/garg-rajdhani.webp", name: "Garg rajdhani" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686131/irctc/vendor/homeLogos/yatribhojan-logo.webp", name: "Yatribhojan" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686132/irctc/vendor/homeLogos/rail-recipe.webp", name: "Rail Recipe" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686132/irctc/vendor/homeLogos/olf-store-logo.webp", name: "Olf Store" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686132/irctc/vendor/homeLogos/spicy-wagon.webp", name: "Spicy Wagon" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686132/irctc/vendor/homeLogos/rajbhog-khana-logo.webp", name: "Rajbhog Khana" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686132/irctc/vendor/homeLogos/gofoodieonline.webp", name: "Gofoodie Online" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75/v1656686132/irctc/vendor/homeLogos/train-dhaba.webp", name: "Train Dhaba" },
  { img: "https://neon.ipsator.com/c/image/upload/q_75,c_scale,w_240/v1691561978/irctc/vendor/homeLogos/nstore.webp", name: "nStore" },
];
// "https://carbon-static.ipsator.com/static/assets/images/vendor-logos/railrestro.jpg",

export const BrandList: React.FC<BrandList> = ({ isMobile }) => {
  return (
    <div className={`flex ${isMobile ? "flex-wrap gap-3" : "justify-center gap-x-20 gap-y-16 px-5"} flex-wrap`}>
      {vendorImages.map((brand, i) => (
        <div
          className={`${
            isMobile ? " flex flex-shrink-0 items-center justify-center border border-gray-20 bg-white px-4 py-2" : ""
          } flex-shrink-0 bg-white`}
          key={i}
        >
          {/* <Image src={`/images/${brand.title}/Image@3x.png`} key={brand.title} width={brand.width} height={brand.height} className="" /> */}
          <Image src={brand.img} width={240} height={120} className="" alt={brand.name} removeDefaultStyle removeBorder />
        </div>
      ))}
    </div>
  );
};
