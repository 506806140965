import { IconCross, IconSearch, Loader } from "@assets/icons";
import Input from "@components/Common/input";
import React from "react";

type Props = {
  onValueChange?: (arg0: string) => void;
  onClick?: (arg0: React.MouseEvent<HTMLDivElement>) => void;
  searchQuery?: string;
  className?: string;
  placeholder: string;
  showSpinner?: boolean;
  isDesktop?: boolean;
};

const SearchInputField = React.forwardRef<HTMLInputElement, Props>(
  ({ onClick, onValueChange, placeholder, searchQuery = "", className = "", showSpinner = false, isDesktop = false }, ref) => {
    const clearSearchQuery = () => {
      onValueChange && onValueChange("");
    };

    const onSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onValueChange && onValueChange(e.target.value);
    };

    const readOnly = !onValueChange;

    const showIconOnRight = !!searchQuery || !!showSpinner;

    return (
      <div className="relative w-full" onClick={onClick}>
        <div className="absolute flex h-full w-12 items-center justify-center px-2">
          <IconSearch color="#999" className={isDesktop ? "text-3xl font-semibold" : ""} />
        </div>
        <Input
          onChange={onSearchQueryChange}
          value={searchQuery}
          className={`form-input pl-12 ${showIconOnRight ? "pr-12" : ""} ${className}`}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
        />
        {showIconOnRight && (
          <div className="absolute right-2 top-0 flex h-full w-10 items-center justify-center">
            {showSpinner ? (
              <Loader color="text-brand-primary" />
            ) : (
              <button onClick={clearSearchQuery} className="flex h-full items-center justify-center ">
                <IconCross className=" text-gray-400" />
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
);

SearchInputField.displayName = "SearchInputField";

export default SearchInputField;
