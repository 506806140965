import { BlogInfoForHomePage } from "@interfaces/index";
import { dateTimeFormatter } from "@services/utils/DateTimeFormatter";
import Link from "next/link";
import React, { useMemo, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";

type BlogCarouselProps = {
  recentPosts: BlogInfoForHomePage[];
};

export const BlogCarousel: React.FC<BlogCarouselProps> = ({ recentPosts }) => {
  function nextSlide(id: string) {
    let activeSlide = document.querySelector(`.${id}.translate-x-0`);
    let nextSlide = activeSlide?.nextElementSibling;
    console.log(activeSlide, nextSlide);

    if (activeSlide && nextSlide) {
      activeSlide.classList.remove("translate-x-0");
      activeSlide.classList.add("-translate-x-full");

      nextSlide.classList.remove("translate-x-full");
      nextSlide.classList.add("translate-x-0");
    }
  }

  function previousSlide(id: string) {
    let activeSlide = document.querySelector(`.${id}.translate-x-0`);
    let previousSlide = activeSlide?.previousElementSibling;

    if (activeSlide && previousSlide) {
      activeSlide.classList.remove("translate-x-0");
      activeSlide.classList.add("translate-x-full");

      previousSlide.classList.remove("-translate-x-full");
      previousSlide.classList.add("translate-x-0");
    }
  }

  const [currentStep, setCurrentStep] = useState(1);
  const [rightEndReached, setRightEndReached] = useState(false);

  const recentPostsOuterRef = useRef<HTMLDivElement>(null);

  const scrollRecentPosts = (type: "left" | "right") => {
    let updatedStep = currentStep;
    if (type === "right") {
      updatedStep += 1;
      carouselRef.current?.onClickNext();
    } else {
      carouselRef.current?.onClickPrev();
      updatedStep - +1;
    }
    setCurrentStep(updatedStep);
  };

  const renderCard = (post: BlogInfoForHomePage) => {
    const formattedDate = dateTimeFormatter(post.date, "short");

    return (
      <Link href={"/blog/" + post.slug} key={post.slug} className="w-3/10 flex-shrink-0 rounded bg-white px-4 py-5 shadow-card">
        <article>
          <h6 className="mb-1 h-16 line-clamp-2">{post.title}</h6>
          <p className="mb-3 text-xs font-medium text-info-tag-2">
            {formattedDate.dateString} {formattedDate.year}
          </p>
          <p className="mb-4 text-base leading-relaxed text-gray-60 line-clamp-3">{post.excerpt}</p>
          <span className="flex items-center font-medium text-gray-60">
            Read More
            <span className="material-symbols-outlined  ml-1 h-6 w-4 font-semibold text-brand-ultraLight">trending_flat</span>
          </span>
        </article>
      </Link>
    );
  };

  const recentPostsToShow = useMemo(() => {
    const postsToShow: BlogInfoForHomePage[][] = [];

    let _temp: BlogInfoForHomePage[] = [];
    let i = 0;

    recentPosts?.forEach((post) => {
      if (i === 3) {
        postsToShow.push(_temp);
        _temp = [];
        i = 0;
      }

      _temp.push(post);
      i++;
    });

    if (_temp.length) {
      postsToShow.push(_temp);
    }

    return postsToShow;
  }, [recentPosts]);

  const carouselRef = useRef<Carousel>(null);

  return (
    <div className="relative w-full">
      <div className={"absolute -top-14 right-0 flex items-end justify-end gap-2 "}>
        <button className={"form-button text-2xl shadow-md"} onClick={() => scrollRecentPosts("left")}>
          {/* <button className={"form-button shadow-md text-2xl"} onClick={() => previousSlide('left')}> */}‹
        </button>
        <button className={"form-button text-2xl shadow-md"} onClick={() => scrollRecentPosts("right")}>
          {/* <button className={"form-button shadow-md text-2xl"} onClick={() => nextSlide('right')}> */}›
        </button>
      </div>
      {/* <div  ref={recentPostsOuterRef}> */}
      {/* transition-all ease-in-out duration-1000 transform ${
              currentStep === i + 1 ? "translate-x-0" : currentStep < i + 1 ? "translate-x-full" : "-translate-x-full"
            }  slide2
             */}
      <Carousel
        className="relative w-full overflow-hidden p-5"
        showArrows={false}
        ref={carouselRef}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
      >
        {recentPostsToShow.map((postsGroup, i) => (
          <div key={i} className={`slide2 inset-0 flex flex-row gap-6 px-2 py-5 text-left`}>
            {postsGroup.map((post) => renderCard(post))}
          </div>
        ))}
      </Carousel>

      {/* <div className={"flex absolute gap-4 inset-0 w-full h-full transition-all ease-in-out duration-1000 transform translate-x-0 slide2"}>
              <div className={"card"}>
                <h4 className={"mt-6 font-semibold text-lg"}>It is greatly interesting title about a news items or another blog type thin...</h4>
                <small className={"font-semibold text-info-tag-2 text-xs"}>12 Feb 2020</small>
                <p className={"font-medium text-gray-50 mt-4"}>
                  “I loved the food that I got. It was so refreshing to eat something apart from the boring train pantry food. Order was delivered
                  easily and food was hot and fresh. Recommended to everyone travelling in trains!
                </p>
              </div>
              <div className={"card"}>
                <h4 className={"mt-6 font-semibold text-lg"}>It is greatly interesting title about a news items or another blog type thin...</h4>
                <small className={"font-semibold text-info-tag-2 text-xs"}>12 Feb 2020</small>
                <p className={"font-medium text-gray-50 mt-4"}>
                  “I loved the food that I got. It was so refreshing to eat something apart from the boring train pantry food. Order was delivered
                  easily and food was hot and fresh. Recommended to everyone travelling in trains!
                </p>
              </div>
              <div className={"card"}>
                <h4 className={"mt-6 font-semibold text-lg"}>It is greatly interesting title about a news items or another blog type thin...</h4>
                <small className={"font-semibold text-info-tag-2 text-xs"}>12 Feb 2020</small>
                <p className={"font-medium text-gray-50 mt-4"}>
                  “I loved the food that I got. It was so refreshing to eat something apart from the boring train pantry food. Order was delivered
                  easily and food was hot and fresh. Recommended to everyone travelling in trains!
                </p>
              </div>
            </div>

            <div className={"flex absolute gap-4 inset-0 w-full h-full transition-all ease-in-out duration-1000 transform translate-x-full slide2"}>
              <div className={"card"}>
                <h4 className={"mt-6 font-semibold text-lg"}>It is greatly interesting title about a news items or another blog type thin...</h4>
                <small className={"font-semibold text-info-tag-2 text-xs"}>12 Feb 2020</small>
                <p className={"font-medium text-gray-50 mt-4"}>
                  “I loved the food that I got. It was so refreshing to eat something apart from the boring train pantry food. Order was delivered
                  easily and food was hot and fresh. Recommended to everyone travelling in trains!
                </p>
              </div>
              <div className={"card"}>
                <h4 className={"mt-6 font-semibold text-lg"}>It is greatly interesting title about a news items or another blog type thin...</h4>
                <small className={"font-semibold text-info-tag-2 text-xs"}>12 Feb 2020</small>
                <p className={"font-medium text-gray-50 mt-4"}>
                  “I loved the food that I got. It was so refreshing to eat something apart from the boring train pantry food. Order was delivered
                  easily and food was hot and fresh. Recommended to everyone travelling in trains!
                </p>
              </div>
              <div className={"card"}>
                <h4 className={"mt-6 font-semibold text-lg"}>It is greatly interesting title about a news items or another blog type thin...</h4>
                <small className={"font-semibold text-info-tag-2 text-xs"}>12 Feb 2020</small>
                <p className={"font-medium text-gray-50 mt-4"}>
                  “I loved the food that I got. It was so refreshing to eat something apart from the boring train pantry food. Order was delivered
                  easily and food was hot and fresh. Recommended to everyone travelling in trains!
                </p>
              </div>
            </div> */}
      {/* </div> */}
    </div>
  );
};
