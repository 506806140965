import React from "react";
import { ElementId } from "../../../enums/common";

export const DownloadAppSection = () => {
  return (
    <div className=" bg-info-wrapper" id={ElementId.downloadAppSection}>
      <div className={"flex flex-row pr-18 h-120 screen-content-width px-10 justify-between items-center overflow-hidden "}>
        <div className={"w-2/5 "}>
          <h4 className="mb-6">
            Download the Food on Track app!
            {/* <br /> */}
            <p className="text-gray-60 text-lg">For order status updates &amp; more</p>
          </h4>

          <p className={"text-gray-60 text-lg mb-9"}>
            With handy features like timely order status updates, one-tap calling &amp; PNR auto-pasting, lesser data usage, faster load times &amp;
            fluid animations, the app is crafted to delight you with a great eCatering order experience.
          </p>

          <div className="flex -mt-2.5 -ml-4">
            {/* <a
                className="form-button-fill normal-case rounded-lg bg-black h-16 flex items-center gap-4 py-3 px-10 flex-shrink-0"
                href="https://apps.apple.com/in/app/irctc-catering-food-on-track/id1077183717"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconApple className="w-10 h-12 mr-2" />
                <div className="text-left">
                  <p className="text-sm">Download on the</p>
                  <h6 className="text-white">App Store</h6>
                </div>
              </a> */}
            <a href="https://apps.apple.com/in/app/irctc-catering-food-on-track/id1077183717">
              <img height="99px" alt="Download on the App Store" src="https://carbon-v2.ipsator.com/static/images/app-store-download-h99.png" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.irctc.fot&utm_source=eCatering_Desktop_Website&utm_campaign=Download_Section_Badge">
              <img height="99px" alt="Get it on Google Play" src="https://carbon-v2.ipsator.com/static/images/play-store-download-h99.png" />
            </a>
            {/* <a
                className="form-button-fill normal-case rounded-lg bg-black h-16 flex items-center gap-4 py-3 px-10 flex-shrink-0"
                href="https://onelink.to/fot-download"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconPlaystore color={"#fff"} />
                <div className="text-left ml-3">
                  <p className="text-sm">Get it on Google</p>
                  <h6 className="text-white">Play Store</h6>
                </div>
              </a> */}
          </div>
        </div>
        <div className={"w-5/12 mt-10 relative"}>
          <img src={"https://carbon-v2.ipsator.com/static/images/fot-oneplus-7-q-auto.png"} className="" alt="Food on Track Mobile App" />
        </div>
      </div>
    </div>
  );
};
