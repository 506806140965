import { IconProps } from "@interfaces/index";
import React from "react";

type AboutECateringText = IconProps & {
  seoContent?: string;
};

export const AboutECateringText: React.FC<AboutECateringText> = ({ seoContent }) => {
  if (seoContent) {
    return <div className="text-small blogPost__body p-10" dangerouslySetInnerHTML={{ __html: seoContent }} />;
  } else return <></>;
};
