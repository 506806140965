import React from "react";
import Carousel from "@components/Carousel";
import { StationListItem } from "@enums/stations-list";
import Link from "next/link";

export interface HomePageStations {
  // stations: StationInfoForHomePage[];
  stations: StationListItem[][];
  isMobile: boolean;
}

export const HomePageStationsList: React.FC<HomePageStations> = ({ stations, isMobile }) => {
  return (
    <Carousel>
      {stations?.map((stationGroup, idx) => (
        <div className={isMobile ? "flex gap-4 flex-wrap" : "grid grid-cols-4 gap-x-6 gap-y-6 w-full"} key={idx}>
          {stationGroup.map((station) => (
            <Link
              href={`/station/${station.code}/outlets`}
              key={station.code}
              className={`flex items-center justify-center h-15 capitalize ${
                isMobile ? `text-xs px-2 w-28 station${(idx % 3) + 1} flex-shrink-0` : "text-lg font-medium text-gray-60 w-full bg-white"
              } rounded shadow-sm`}
            >
              {station.name}
            </Link>
          ))}
        </div>
      ))}
    </Carousel>
  );
};
