import Layout from "@components/Layout";
import { BottomSheet, Modal } from "@components/Modals";
import HomeDesktop from "@components/PageComponents/home/index.desktop";
import { MobileHome } from "@components/PageComponents/home/index.mobile";
import SearchInputField from "@components/PageComponents/home/SearchInputField";
import { HomePageStations } from "@components/PageComponents/home/StaticStationsList";
import { TrainStationSearchQuery } from "@components/PageComponents/home/TrainStationSearchQuery";
import { stationsList } from "@enums/stations-list";
import useMounted from "@hooks/useMounted";
import usePnrContext from "@hooks/usePnrContext";
import usePnrField from "@hooks/usePnrField";
import { BaseProps, BlogInfoForHomePage, HomeProps, searchHistoryItem } from "@interfaces/index";
import { getHomeData } from "@services/server/ServerApis";
import { getPageSEOContent, getPostsForHomePage } from "@services/server/wordpress";
import { clearCurrentOrderInfoInStorage, getPageContainer, getPnrSearchHistoryFromStorage } from "@services/utils/Common";
import { Config } from "@services/utils/Config";
import { getBrowserInfo } from "@utils/browser";
import { GetServerSideProps, GetServerSidePropsContext } from "next";
import { useRouter } from "next/router";
import { ParsedUrlQuery } from "querystring";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { SWRConfig } from "swr";

type IndexPageProps = BaseProps & {
  // stationsList: StationInfoForHomePage[];
  seoContent: any;
  stationsList: HomeProps["stations"];
  blogPosts: BlogInfoForHomePage[];
  homeResponse?: any;
  routeQuery?: ParsedUrlQuery;
};

const IndexPage: React.FC<IndexPageProps> = ({ browserInfo, seoContent, stationsList, blogPosts, homeResponse, routeQuery }) => {
  const historyData = useMemo(() => getPnrSearchHistoryFromStorage(), []);
  const isMobile = browserInfo?.isMobile;
  const router = useRouter();
  const { query } = router;
  const [showTrainSearchQueryModal, toggleTrainSearchQueryModal] = useState(query.showQuery === "true");
  const trainSearchQueryModalRef = useRef<Modal>(null);
  const pnrContext = usePnrContext();
  const mounted = useMounted();

  const onPnrFieldFocus = (e: React.FocusEvent) => {
    if (isMobile) {
      // Move PNR field into the view on focus in mobile
      const { top } = e.currentTarget.getBoundingClientRect();
      const pageContainer = getPageContainer();
      pageContainer?.scrollTo({ top: top - 200 });
    }
  };

  const { renderPnrField: __renderPnrField, handleRetrievePnrInfo } = usePnrField({ isMobile, enablePantry: routeQuery?.enablePantry === "true" });

  const renderPnrField = () => __renderPnrField({ className: isMobile ? "h-14 flex-1" : "h-16 flex-1", onPnrFieldFocus });

  const handleJourneyItemClick = (record: searchHistoryItem) => {
    handleRetrievePnrInfo(record.pnr);
  };

  const handleToggleTrainSearchQueryModal = () => {
    toggleTrainSearchQueryModal((t) => !t);
  };

  const renderSearchByField = () => (
    <SearchInputField
      placeholder="Search Train or Station to explore"
      className={`${isMobile ? "h-14" : "h-16"} pl-12 pr-4 text-lg shadow-6dp`}
      onClick={handleToggleTrainSearchQueryModal}
      isDesktop={!isMobile}
    />
  );

  useEffect(() => {
    if (mounted) {
      if (showTrainSearchQueryModal) {
        router.push(`/?showQuery=true&showTrainSearch=${query.showTrainSearch === "true"}`, undefined, { shallow: true });
      } else {
        router.replace("", undefined, { shallow: true });
      }
    }
  }, [showTrainSearchQueryModal, mounted]);

  const toggleTrainMode = () => {
    router.push(`/?showQuery=true&showTrainSearch=${query.showTrainSearch === "true" ? "false" : "true"}`, undefined, { shallow: true });
  };

  useEffect(() => {
    pnrContext.resetState?.();
    clearCurrentOrderInfoInStorage();
  }, []);

  const findByTrainMode = query.showTrainSearch === "true";

  const TrainStationSearchQueryContainer = isMobile ? BottomSheet : Modal;

  useEffect(() => {
    if (!isMobile) {
      const pageContainer = getPageContainer();
      if (pageContainer && trainSearchQueryModalRef.current) {
        // This is done to center the query modal box w.r.t the pnr search field in the home page
        // Content in home page shifts to left because of the scroll
        // -ve additional margin left is added to compensate for the scroll in home page
        const diffWidth = window.innerWidth - pageContainer?.clientWidth;
        trainSearchQueryModalRef.current.style.marginLeft = `-${diffWidth / 2 + 1}px`;
      }
    }
  }, [isMobile, mounted]);

  return (
    <SWRConfig
      value={{
        onErrorRetry: (error: any) => {
          // Never retry on 401.
          if (error.status === 401) return;
        },
      }}
    >
      <Layout isMobile={browserInfo?.isMobile}>
        {isMobile ? (
          <MobileHome
            renderPnrField={renderPnrField}
            renderSearchByField={renderSearchByField}
            historyData={historyData}
            handleJourneyItemClick={handleJourneyItemClick}
            isIosMobile={browserInfo?.isIosMobile}
            seoContent={seoContent?.content}
            browserInfo={browserInfo}
          />
        ) : (
          <HomeDesktop
            renderPnrField={renderPnrField}
            historyData={historyData}
            handleJourneyItemClick={handleJourneyItemClick}
            stations={stationsList}
            blogPosts={blogPosts}
            renderSearchByField={renderSearchByField}
            seoContent={seoContent?.content}
            browserInfo={{
              isMobile: false,
              isWebview: undefined,
              browser: undefined,
              os: undefined,
              platform: undefined,
              engine: undefined,
              isIosMobile: false,
            }}
          />
        )}
        <TrainStationSearchQueryContainer
          show={showTrainSearchQueryModal}
          toggleClose={handleToggleTrainSearchQueryModal}
          title=""
          subtitle=""
          outerBoxClassName={`${isMobile ? "h-5/6" : "w-96 pb-4 h-75vh max-h-133"} z-40`}
          childClassName={`flex flex-col`}
          alignment="bottom"
          ref={trainSearchQueryModalRef}
        >
          <TrainStationSearchQuery
            serverHomeResponse={homeResponse}
            closeSearchQueryModal={handleToggleTrainSearchQueryModal}
            showSearchQueryModal={showTrainSearchQueryModal}
            isMounted={mounted}
            toggleTrainMode={toggleTrainMode}
            findByTrainMode={findByTrainMode}
            isMobile={browserInfo?.isMobile}
          />
        </TrainStationSearchQueryContainer>
      </Layout>
    </SWRConfig>
  );
};

export default IndexPage;

export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
  // console.log(context);

  const routeQuery = context.query;
  const browserInfo = getBrowserInfo(context);
  let stations: HomePageStations["stations"] = [];
  let blogPosts: BlogInfoForHomePage[] = [];

  const seoContent = await getPageSEOContent("home", browserInfo?.isMobile);

  if (!browserInfo?.isMobile) {
    // stationsList = getStationsListFromJson();
    if (Config.wordpressEnabled) {
      blogPosts = (await getPostsForHomePage()) || [];
    }
    stations = stationsList;
  }

  const homeResponse = await getHomeData(context);

  const props: IndexPageProps = {
    browserInfo,
    seoContent,
    stationsList: stations,
    blogPosts,
    homeResponse: homeResponse?.status === "success" ? homeResponse : null,
    routeQuery,
  };
  return {
    props, // will be passed to the page component as props
  };
};
