import React from "react";

type CardProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Card: React.FC<CardProps> = ({ className, children, ...props }) => {
  return (
    <div className={`rounded bg-white ${className}`} {...props}>
      {children}
    </div>
  );
};

export default Card;
