import { PeopleOutlineIcon } from "@assets/icons";
import Card from "@components/Card";
import Images from "@services/utils/Images";
import Link from "next/link";
import React from "react";

interface GroupBookingProps {
  isMobile: boolean;
}

const GroupBooking: React.FC<GroupBookingProps> = ({ isMobile }) => {
  return (
    <Link href="/callback">
      {isMobile ? (
        <div className="mb-6 px-4 pb-1">
          <div className="subtitle-1 mb-3">Group Booking</div>
          <Card className=" flex flex-row items-center py-4 px-3 shadow-card">
            <PeopleOutlineIcon className="active mr-1 text-3xl text-brand-primary" />
            <div className="body-1 flex flex-1 px-2 text-gray-60">Travelling as a group of 15 or more people?</div>
            <button className="rounded border border-brand-primary border-opacity-25 p-2 text-center font-medium uppercase text-brand-primary focus:bg-brand-light focus:text-white">
              Group booking
            </button>
          </Card>
        </div>
      ) : (
        <div className={"screen-content-width flex max-h-40 cursor-pointer items-center gap-8 overflow-y-hidden rounded bg-white pr-10 shadow-card"}>
          <div className={"image-fade-l-r"}>
            <img src={Images.groupBooking} alt={"group booking image"} />
          </div>
          <div className={"flex flex-1 flex-col gap-2"}>
            <div className={"flex items-center gap-4 "}>
              <PeopleOutlineIcon className="text-4xl  text-gray-75" />
              <h4>Group Orders</h4>
            </div>
            <p>
              Ordering for a group of more than 15 people? Easily place group orders, get assisted ordering and custom prices when ordering in bulk
              quantities.
            </p>
          </div>
          <div>
            <button className={"form-button border border-brand-primary border-opacity-25 text-lg"}>Group booking</button>
          </div>
        </div>
      )}
    </Link>
  );
};

export default GroupBooking;
