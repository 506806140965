import React, { useRef } from "react";
import { Carousel as _Carousel } from "react-responsive-carousel";

type CarouselProps = {
  children: React.ReactChild[];
};

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const carouselRef = useRef<_Carousel>(null);

  const renderIndicator = (clickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void, isSelected: boolean, index: number) => (
    <>
      {index === 0 && (
        <button className="form-button shadow-md text-2xl" onClick={() => carouselRef.current?.onClickPrev()}>
          ‹
        </button>
      )}
      <div className="w-3 h-3 flex items-center justify-center">
        <div
          className={`${isSelected ? "w-3 h-3 bg-brand-primary" : "w-2 h-2 bg-gray-40 hover:w-3 hover:h-3 transition-all duration-75"} rounded-lg`}
          onClick={clickHandler}
        />
      </div>
      {index === children.length - 1 && (
        <button className={"form-button shadow-md text-2xl"} onClick={() => carouselRef.current?.onClickNext()}>
          ›
        </button>
      )}
    </>
  );

  return (
    <_Carousel
      className="custom-carousel"
      ref={carouselRef}
      showStatus={false}
      showThumbs={false}
      renderIndicator={renderIndicator}
      showArrows={false}
    >
      {children}
    </_Carousel>
  );
};

export default Carousel;
