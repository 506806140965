import React, { useState } from "react";

type Props<Item = any> = {
  title: string;
  noOfItemsToShowOnCollapsed?: number;
  data: ReadonlyArray<Item>;
  renderListItem: (arg0: Item, isLastItem: boolean) => JSX.Element;
};

export const ListShowMoreComponent: React.FC<Props> = ({ renderListItem, data, title, noOfItemsToShowOnCollapsed = 5 }) => {
  const [isExpanded, toggleExpandedState] = useState(false);

  const itemsToShow = isExpanded ? data : data.slice(0, noOfItemsToShowOnCollapsed);

  const handleToggleExpandedState = () => {
    toggleExpandedState(!isExpanded);
  };

  return (
    <>
      <div className="subtitle-3 h-9 uppercase px-4 bg-gray-30 flex items-center justify-between">
        <span>{title}</span>
        {data.length > noOfItemsToShowOnCollapsed && <button onClick={handleToggleExpandedState}>Show {isExpanded ? "Less" : "More"}</button>}
      </div>
      {itemsToShow.map((item, index, arr) => renderListItem(item, index === arr.length - 1))}
    </>
  );
};
