import { IconChevronRight } from "@assets/icons";
import { searchHistoryItem } from "@interfaces/index";
import { PnrSearchHistory } from "@services/utils/Common";
import { dateTimeFormatter } from "@services/utils/DateTimeFormatter";
import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Card from "../Card";

type CardProps = {
  record: searchHistoryItem;
  layout: string;
  isMobile: boolean;
  classNames?: string;
  handleClick: (record: searchHistoryItem) => void;
};

type Props = {
  layout: string;
  isMobile: boolean;
  classNames?: string;
  showEmptyMessage: boolean;
  showTitle?: boolean;
  historyData: PnrSearchHistory;
  handleJourneyItemClick: (record: searchHistoryItem) => void;
};

function StationCardItem({ record, layout, isMobile, classNames = "", handleClick }: CardProps) {
  const temp = record.trainInfo.dt.split("-");
  const newDate = `${temp[2]}-${temp[1]}-${temp[0]}`;
  const { weekday, longDateString } = dateTimeFormatter(newDate);

  return (
    <Card
      className={`mx-3 mb-1 flex cursor-pointer flex-row items-center justify-between gap-x-3 p-3
      ${classNames} ${layout === "card" ? `${isMobile ? "w-56" : "x-4 m-0 w-72 gap-y-4"} shadow-card` : "w-full border border-gray-15"} ${
        isMobile ? "" : "h-36"
      } journey-history-item flex-shrink-0`}
      onClick={() => handleClick(record)}
    >
      <div className={`flex flex-col`}>
        <div className={`${layout === "row" ? "flex flex-col items-start text-left" : ""} text-left clamp-1`}>
          <span className={`subtitle-3 ${!isMobile && "text-sm"} ${layout === "row" ? "mb-1" : ""} `}>{record.trainInfo.trainNo}</span>{" "}
          <span className={`text-xs ${!isMobile && "text-sm"} ${layout === "row" ? "mb-1 font-medium text-gray-70" : "text-gray-60"}`}>
            {record.trainInfo.name.slice(0, 20)}
          </span>
        </div>
        {/* <div> */}
        <span className={`wrap  ${layout === "row" ? "body-3 text-gray-60" : `${isMobile ? "body-1" : "text-lg"} clamp-2`} text-left`}>
          {record.trainInfo.fromStationName} - {record.trainInfo.toStationName}
        </span>
        {/* </div> */}
        <div className={`body-3 ${!isMobile && "text-sm"} mt-1 text-left text-gray-60`}>
          On {weekday}, {longDateString}
        </div>
      </div>
      <div>
        <IconChevronRight />
      </div>
    </Card>
  );
}

function JourneyHistory({ layout, isMobile = true, classNames = "", showTitle = false, historyData, handleJourneyItemClick }: Props) {
  const [cardsPerPage, setCardsPerPage] = useState<number>(4);
  const totalItems = JourneyHistory.length;
  const numberOfPages = Math.ceil(totalItems / cardsPerPage);
  const createBucket = (bucketItems: Array<Object>, bucketSize: Number) => (buckets: any) => {
    return bucketItems.length === 0 ? buckets : [...buckets, bucketItems.splice(0, cardsPerPage)];
  };

  const bucketWithItems = historyData.reduce(createBucket([...historyData], numberOfPages), []); //makes array with sub-arrays of x items according to viewport-width

  const [viewportWidth, setViewportWidth] = useState<number>(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
  const resize = () => {
    setViewportWidth(Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0));
    console.log("window resized", viewportWidth);
    if (viewportWidth > 1416) {
      setCardsPerPage(4);
    }
    if (viewportWidth < 1416) {
      setCardsPerPage(3);
    }
    if (viewportWidth < 1104) {
      setCardsPerPage(2);
    }
    // if (viewportWidth < 640) {
    //   setCardsPerPage(1);
    // }
  };
  window.onresize = resize;

  useEffect(() => {
    resize();
  }, []);

  const ExternalControlledCarousel = () => {
    const [currentSlide, setCurrentSlide] = useState<number>(0);

    const next = () => {
      setCurrentSlide((x: any) => (x = x + 1));
    };
    const prev = () => {
      setCurrentSlide((x: any) => (x = x - 1));
    };
    return (
      <div>
        <table className="w-full table-fixed">
          <tr>
            <td className="w-21 p-0 text-center">
              {currentSlide !== 0 ? (
                <button onClick={prev} className="journeys-carousel__external-button-left">
                  ‹
                </button>
              ) : null}
            </td>

            <td style={{ width: "calc(100%)" }}>
              {/* <td style={{width:"1248px"}} > */}
              <Carousel
                infiniteLoop={false}
                showArrows={false}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                autoPlay={false}
                selectedItem={currentSlide}
              >
                {bucketWithItems.map((stationGroup: Array<searchHistoryItem>, idx: number) => (
                  <div className={`flex flex-row ${idx == bucketWithItems.length - 1 ? "justify-start" : "justify-start"}`} key={idx}>
                    {stationGroup.map((record: searchHistoryItem) => (
                      <StationCardItem key={record.pnr} record={record} layout={layout} isMobile={isMobile} handleClick={handleJourneyItemClick} />
                    ))}
                  </div>
                ))}
              </Carousel>
            </td>
            <td className="w-21 p-0 text-center">
              {currentSlide !== bucketWithItems.length - 1 ? (
                <button onClick={next} className="journeys-carousel__external-button-right">
                  ›
                </button>
              ) : null}
            </td>
          </tr>
        </table>
      </div>
    );
  };

  return (
    <div className={`w-full ${isMobile ? "" : ""}`}>
      {!!showTitle ? <h4 className={`${isMobile ? "subtitle-1 ml-4" : ""} ${classNames}`}>Journeys</h4> : null}

      {(isMobile || layout === "row") && ( //render on mobile-homepage, mobile-profile, desktop-profile page
        <div
          className={`flex gap-3 ${
            layout === "row"
              ? "w-full flex-col"
              : `hideScrollbar journey-history-container flex-row flex-nowrap overflow-x-auto p-3 ${isMobile ? "px-4" : ""}`
          }`}
        >
          {historyData.map((record: searchHistoryItem) => (
            <StationCardItem key={record.pnr} record={record} layout={layout} isMobile={isMobile} handleClick={handleJourneyItemClick} />
          ))}
        </div>
      )}
      {!isMobile &&
        layout !== "row" && ( //render only on desktop-homepage , next div manages responsiveness of container calculated as (  84px button + 312px per card + 84px button)
          <div
            className="mx-auto"
            style={cardsPerPage === 3 ? { width: "1104px", alignSelf: "center" } : cardsPerPage < 3 ? { width: "792px" } : { width: "1412px" }}
          >
            <ExternalControlledCarousel />
          </div>
        )}
    </div>
  );
}

export default JourneyHistory;
