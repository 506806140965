import { IconFoodServed } from "@assets/icons/IconFoodServed";
import { IconOutlet } from "@assets/icons/IconOutlet";
import { IconPlaystore } from "@assets/icons/IconPlaystore";
import { NavbarDesktop } from "@components/HeadersFooters";
import Image from "@components/Image";
import JourneyHistory from "@components/StationsComponents/StationCardItem";
import { HomeProps } from "@interfaces/index";

import { BlogCarousel } from "@components/Blog/BlogCarousel";
import Carousel from "@components/Carousel";
import { AboutECateringText } from "@components/PageComponents/home/AboutECateringText";
import { BrandList } from "@components/PageComponents/home/BrandList";
import { DownloadAppSection } from "@components/PageComponents/home/DownloadAppSection";
import GroupBooking from "@components/PageComponents/home/GroupBooking";
import HowItWorks from "@components/PageComponents/home/HowItWorks";
import { HomePageStationsList } from "@components/PageComponents/home/StaticStationsList";
import Link from "next/link";
// import VigilanceWeekBanner from "@components/PageComponents/home/VigilanceWeekBanner";
import Images from "@services/utils/Images";

const playStoreReviews = [
  {
    name: "Nikki Sharma",
    desc: "Great experience. I would suggest travellers to use this app and get hot and tasty food delivered on your seat. Loved the quality of food delivered. Thanks IRCTC for this initiative.",
  },
  {
    name: "Sandeep Naruka",
    desc: "Great to order from here. Quality can be trusted. Great initiative by IRCTC. Now if you don't feel like ordering from pantry car on your long journey, you may enjoy food from great menu of available restaurant nearby. Appreciate it.",
  },
  {
    name: "Razee Ahmad",
    desc: "It was the beyond expectations experience,just amazing never expected this much as we have ordered first time but the food was amazing from quality to everything,the packing,service,surprise item even sanitizer you just made our day ,Thank you so much ..will further order everytime I travel in train.",
  },
  {
    name: "Mukul Kushwaha",
    desc: "While ordering first time I was hesitating about service and food quality but at that time no other app were showing delivery status for pipariya. So finally I had booked my food and got at my seat. It was a briyani, gulab jamun, french fries. Everything was just above yummy. Thanks for service and my upcoming journey food delivery partner will be irctc e catering app.",
  },
];

function HomeDesktop({
  renderPnrField,
  historyData,
  handleJourneyItemClick,
  stations,
  blogPosts,
  renderSearchByField,
  seoContent,
}: Omit<HomeProps, "isIosMobile">) {
  return (
    <div className=" h-full">
      <NavbarDesktop />
      <div className="mb-10 flex h-135 flex-col  items-center justify-between bg-desktopHome bg-cover bg-top bg-no-repeat pb-8 pt-24 shadow-inner-lg">
        <div className="flex flex-col items-center">
          <div className="mb-12 flex h-24 items-center justify-center rounded-xl bg-white pl-4 pr-4" style={{ marginTop: -36 }}>
            <img src={Images.eCateringLogo} />
          </div>
          <h1 className="home-page-title mb-8 text-center text-4.5xl font-medium leading-14 tracking-wide text-white">
            Get restaurant food delivered right at your seat
          </h1>
          <div className="flex w-96 flex-col">
            <div className="mb-6 flex-shrink-0">{renderSearchByField()}</div>
            <div className="flex-shrink-0">{renderPnrField()}</div>
          </div>
        </div>
      </div>

      {historyData.length !== 0 && (
        <div className=" mb-13">
          <div>
            {/* <div className={"flex flex-wrap gap-8 max--xl mx-auto items-center cursor-pointer"}> */}
            <JourneyHistory
              historyData={historyData}
              layout={"card"}
              isMobile={false}
              classNames={"text-center mb-7 home"}
              showEmptyMessage={false}
              showTitle={true}
              handleJourneyItemClick={handleJourneyItemClick}
            />
          </div>
        </div>
      )}

      <div className="mb-10">
        <div
          className={
            "screen-content-width flex max-h-40 items-center gap-8 overflow-y-hidden rounded-xl border border-gray-700 bg-white object-none py-2 pl-16 pr-12"
          }
        >
          <div>
            <img src={Images.irctcTitleImg} alt={"IRCTC tagline image"} />
          </div>
          <div className={"border-l-2 border-r-gray-600 py-6 pl-8"}>
            <p className="text-xl font-medium tracking-[0.15px]">
              Official website of Indian Railways <br /> and IRCTC to order food on trains
            </p>
          </div>
          <div>
            <img src={Images.irctcTrainArtWork} alt={"IRCTC tagline image"} />
          </div>
        </div>
      </div>

      {/*group booking banner*/}
      <div className=" mb-10">
        <GroupBooking isMobile={false} />
      </div>

      {/*how it works*/}
      {historyData.length === 0 && (
        <div className=" mb-20">
          <div className={"screen-content-width flex flex-col items-center justify-center gap-8"}>
            <HowItWorks />
            {/* <h4 className="text-2.5xl">How it works</h4>
            <div className={"flex gap-12 text-md pb-14 text-center items-start"}>
              {howItWorksItems.map((item, i) => (
                <div className={"p-4 flex flex-col items-center gap-4 w-72"} key={item.title}>
                  <Image src={item.img} width={85} height={100} objectFit={"contain"} />
                  <div className={"flex justify-between items-center border border-alert-primary w-max rounded pr-4 overflow-hidden h-9.5"}>
                    <span className={"bg-brand-ultraLight text-white m-0 h-full font-bold w-9 mr-2 leading-snug text-2.5xl border-cut"}>{i + 1}</span>
                    <span className="italic font-bold text-xl text-gray-60">{item.title}</span>
                  </div>
                  <p className="text-gray-60 text-lg max-w-50">{item.subtitle}</p>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      )}

      {/*vendors*/}
      <div className=" max-s mb-20">
        <div className={"flex flex-col items-center justify-center gap-10 bg-white py-10"}>
          <h4 className="text-2.5xl">Order from the best brands</h4>
          <div className=" w-full">
            <div className="screen-content-width w-full">
              <BrandList isMobile={false} />
            </div>
          </div>
          <span className="text-lg text-gray-60">And many more for you to choose from!</span>
        </div>
      </div>

      {/*cuisines*/}
      {/* <div className={" mb-20"}>
        <div className={"flex flex-col gap-8 max--xl mx-auto items-center justify-center "}>
          <h4>Cuisines on offer</h4>
          <div className={"grid grid-cols-4 gap-16"}>
            {[...Array(visibleCuisines)].map((x, idx) => (
              <Image src={SampleCuisine} key={idx} />
            ))}
          </div>
          {totalCuisines > visibleCuisines && visibleCuisines <= 8 && (
            <span className={"cursor-pointer text-brand-primary font-semibold"} onClick={() => setVisibleCuisines(totalCuisines)}>
              View All Cuisines
            </span>
          )}
        </div>
      </div> */}

      {/*stations*/}
      <div className={" mb-20"}>
        <div className={"screen-content-width flex flex-col items-center justify-center gap-10 px-8 "}>
          <h4 className="text-2.5xl">Some of the important stations we deliver at</h4>
          <HomePageStationsList stations={stations} isMobile={false} />
          {/* <span className={"cursor-pointer subtitle-1 text-brand-primary "}>View All Active Stations</span> */}
        </div>
      </div>

      {/*review display*/}
      <div className=" mb-20">
        <div className="screen-content-width relative text-center">
          <h4 className="mb-16 text-2.5xl">What our customers say</h4>
          <div className="flex w-full justify-between pl-10">
            <div className={"flex flex-col items-center pt-10"}>
              <IconPlaystore className="active text-4.5xl leading-6 " color="#16ac4a" />
              <h4 className="mb-0.5 mt-3">50L+ Installs</h4>
              <p className="text-base text-gray-60">On Google Play</p>
            </div>
            <div className={"flex flex-col items-center pt-10"}>
              <IconFoodServed className="active text-5xl leading-6 " color="#d87f18" />
              <h4 className="mb-0.5 mt-3">1Cr+ Meals</h4>
              <p className="text-base text-gray-60">Ordered</p>
            </div>
            <div className={"flex flex-col items-center pt-10"}>
              <IconOutlet className="text-4.5xl leading-6" color="#e3d527" />
              <h4 className="mb-0.5 mt-3">1K+ Restaurants</h4>
              <p className="text-base text-gray-60">To Order From</p>
            </div>

            <div className="relative w-2/4">
              <Carousel>
                {playStoreReviews.map((review) => (
                  <div className={"w-full text-left"} key={review.name}>
                    <h4 className="mb-1">{review.name}</h4>
                    <p className={"subtitle-1 mb-4 text-info-tag-2"}>Rated 5 &#9733;</p>
                    <p className={"text-base leading-normal text-gray-70"}>&quot;{review.desc}&quot;</p>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>

      <DownloadAppSection />

      {/*vendor registration*/}
      <div className=" my-20">
        <a
          className={"screen-content-width vendor-register flex h-56 items-center justify-between rounded px-28 text-white shadow-card"}
          href="https://www.ecatering.irctc.co.in/admin/vendor-signup"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <h2 className={"mb-2 text-2xl font-medium text-white"}>Are you a restaurant owner? Register as a vendor</h2>
            <p className="mb-6 text-lg font-medium">We are always looking to partner with reputed and quality restaurants</p>
            <button className={"form-button-fill bg-white"}>
              <h6>Register Now</h6>
            </button>
          </div>
          <Image removeBorder={true} src={Images.sampleVendor} alt="Vendor Register" />
        </a>
      </div>
      <div className=" mb-20">
        <div className="screen-content-width">
          <div className={"mb-20 flex flex-col items-center"}>
            <h4 className="mb-10 text-2.5xl">News and Blogs About eCatering</h4>
            <BlogCarousel recentPosts={blogPosts} />
            <Link href="/blog" className={"subtitle-1 mt-3 cursor-pointer  text-brand-primary"}>
              View All News and Blogs
            </Link>
          </div>

          {/* <VigilanceWeekBanner isMobile={false} /> */}

          <div className={"flex flex-row items-start gap-8 rounded"}>
            {/* <div className={"w-2/5"}>
              <Image removeBorder={true} src={Images.sampleAbout} alt="About" />
            </div> */}
            <div className={"page-seo-content flex flex-1 flex-col"}>
              {/* <h4 className={"text-2.5xl mb-8"}>Get Good Food Delivery in Trains</h4> */}
              <AboutECateringText seoContent={seoContent} className="flex flex-col gap-4 text-lg text-gray-60" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeDesktop;
